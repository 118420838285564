//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        rowguid: {
            type: String,
            default: '',
        },
        needinvoice: {
            type: String,
            default: '',
        },
    },
    components: {},
    data() {
        return {
            form: {
                file: '',
                number: '',
            },
            fpDetail: {},
        };
    },
    computed: {},
    watch: {},

    methods: {
        submit() {
            if (this.needinvoice == 1) {
                if (this.form.file) {
                    this.$post(
                        `${this.$store.getters.indexUrl}/settle/settBill/invoice`,
                        {
                            billId: this.rowguid,
                            invoiceFile: this.form.file,
                            invoiceNo: this.form.number,
                        },
                        (data, res) => {
                            if (res.code == 'v') {
                                this.cancle();
                                showMsg('提交成功', 'success');
                            } else {
                                showMsg(res.msg, 'warning');
                            }
                        }
                    );
                } else {
                    showMsg('请上传发票文件', 'warning');
                }
            } else {
                this.$post(
                    `${this.$store.getters.indexUrl}/settle/settBill/invoice`,
                    {
                        billId: this.rowguid,
                        invoiceFile: this.form.file,
                        invoiceNo: this.form.number,
                    },
                    (data, res) => {
                        this.cancle();
                        showMsg('提交成功', 'success');
                    }
                );
            }
        },
        cancle() {
            this.$emit('close');
        },
        getfile(val) {
            this.form.file = val.fileUrl;
        },
        getDetail() {
            this.$get(
                `${this.$store.getters.indexUrl}/settle/settBill/invoiceInfo`,
                {
                    billId: this.rowguid,
                },
                (data) => {
                    this.fpDetail = data;
                }
            );
        },
    },
    mounted() {
        this.getDetail();
    },
};
