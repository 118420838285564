//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        rowguid: {
            type: String,
            default: '',
        },
        qdname: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
    },
    components: {},
    data: function () {
        return {
            form: {
                file: '',
            },
            dwDetail: {},
        };
    },
    computed: {},
    watch: {},

    methods: {
        submit() {
            this.$post(
                `${this.$store.getters.indexUrl}/settle/settBill/settle`,
                {
                    billId: this.rowguid,
                    settleFile: this.form.file,
                },
                (data, res) => {
                    this.cancle();

                    showMsg('提交成功', 'success');
                }
            );
        },
        cancle() {
            this.$emit('close');
        },
        getfile(val) {
            this.form.file = val.fileUrl;
        },
    },
    mounted() {
        if (!this.rowguid) {
            return;
        }

        this.$get(
            `${this.$store.getters.indexUrl}/settle/settBill/settleInfo`,
            {
                billId: this.rowguid,
            },
            (data, res) => {
                this.dwDetail = data;
            }
        );
    },
};
