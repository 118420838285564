//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import settlementDatail from './components/settlementDatail.vue';

import billStatus from './data/billStatus';

export default {
    components: {
        settlementDatail,
    },
    props: {},
    data() {
        return {
            detailData: {},

            listStatus: billStatus,
        };
    },
    computed: {
        billId: function () {
            return this.getQuery('billId');
        },
    },
    watch: {},
    methods: {
        getDetail: function () {
            this.$get(
                `${this.$store.getters.indexUrl}/settle/settBill/getInfo`,
                {
                    billId: this.billId,
                },
                function (data) {
                    this.detailData = data;
                }
            );
        },
        //导出
        exportList: function () {
            if (!this.billId) {
                ShowMsg('暂不支持导出', 'warning');
                return;
            }

            var token = this.getQuery('token');

            if (!token) {
                token = this.getGetters('user').token;
            }

            var search = toSearch({
                billId: this.billId,
                isExport: true,
                token,
            });
            
            window.open(
                `${this.$store.getters.indexUrl}/settle/settBill/getInfo${search}`
            );
        },
        //返回
        goBack: function () {
            this.$router.go(-1);
        },
        //下载文件
        downloadFile: function (val) {
            window.open(val);
        },
        //筛选状态
        filterStatus: function (arr, val) {
            let status = arr.filter(function (item) {
                return item.key == val;
            });
            if (status != '') {
                return status[0]; //返回筛选的对象
            } else {
                return {};
            }
        },
    },
    mounted: function () {
        this.getDetail();
    },
};
