//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page';
import COMMON_METHODS from '@mixins/common-methods.js';
import Add from './add.vue';
import uploadfp from './components/uploadfp.vue';
import uploadpz from './components/uploadpz.vue';
import billStatus from './data/billStatus';

export default {
    mixins: [LIST_MIXIN, COMMON_METHODS],
    props: [],
    components: { Add, uploadpz, uploadfp },
    data: function () {
        return {
            pgData: {
                billName: '',
                billStatus: '',
                channelId: '',
                productId: '',
                listType: ['0'].includes(this.$store.getters.user.registertype) ? '2' : '1',
            },
            tableData: [],
            dialogApplyVisible: false,
            dialogUploadfpVisible: false,
            dialogUploadpzVisible: false,
            formTitle: '',
            billId: '',
            requestUrl: '',
            token: '',
            listStatus: billStatus,
            needinvoice: '',
            partnerNamelist: [],

            tableHeightPage: window.innerHeight - 295,
        };
    },
    computed: {
        listTypeData() {
            var arr = [];

            if (!['0'].includes(this.$store.getters.user.registertype)) {
                arr.push({ value: '我的结算记录', key: '1' });
            }

            arr.push({ value: '下级结算记录', key: '2' });

            return arr;
        },
    },
    watch: {
        dialogApplyVisible: function (n, o) {
            if (!n) {
                this.doSearch();
            }
        },
        dialogUploadpzVisible: function (n, o) {
            if (!n) {
                this.doSearch();
            }
        },
        dialogUploadfpVisible: function (n, o) {
            if (!n) {
                this.doSearch();
            }
        },
    },

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },
        //申请结算
        applyJS: function () {
            this.dialogApplyVisible = true;
            this.formTitle = '申请结算';
        },
        //清单详情
        listDetail: function (row) {
            var query = {
                    billId: row.billId,
                    token: this.getQuery('token'),
                },
                token = this.getQuery('token');

            if (token) {
                query.token = token;
            }

            this.goto({
                path: './detail',
                query,
            });
        },
        //清单确认
        listAffirm: function (row) {
            showConfirm(
                `<span style="font-size:15px;">是否确认 ${row.billName} 清单？</span>`,
                'warning',
                () => {
                    this.$post(
                        `${this.$store.getters.indexUrl}/settle/settBill/confirm`,
                        {
                            billId: row.billId,
                        },
                        (data, res) => {
                            showMsg('确认成功', 'success');
                            this.doSearch();
                        }
                    );
                },
                function () {}
            );
        },
        //删除清单
        listDel: function (row) {
            showConfirm(
                '删除后需重新添加结算清单，是否确定？',
                'warning',
                () => {
                    this.$post(
                        `${this.$store.getters.indexUrl}/settle/settBill/remove`,
                        {
                            billId: row.billId,
                        },
                        () => {
                            showMsg('删除成功', 'success');
                            this.doSearch();
                        }
                    );
                },
                () => {
                    showMsg('已取消删除操作', 'info');
                }
            );
        },
        //开具发票
        kfp: function (row) {
            this.needinvoice =
                this.getQuery('needinvoice') ||
                this.getGetters('user').needinvoice;

            this.formTitle = '上传发票';
            this.billId = row.billId;

            this.dialogUploadfpVisible = true;
        },
        //财务确认
        uploadPz: function (row) {
            this.formTitle = '清单结算';
            this.billId = row.billId;
            this.billName = row.billName;

            this.dialogUploadpzVisible = true;
        },
        //导出清单
        exportList: function () {
            if (this.tableData.length != 0) {
                var search = toSearch({
                    ...this.pgData,
                    token:
                        this.getQuery('token') || this.getGetters('user').token,
                    isExport: true,
                });
                window.open(`${this.$store.getters.indexUrl}/settle/settBill/listSettlePage${search}`);
            } else {
                showMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //获取合作单位
        getPartnerName: function () {
            this.$get(
                `${this.getGetters('partnerUrl')}/channelInfo/listSelect`,
                {},
                (data) => {
                    this.partnerNamelist = data;
                }
            );
        },
    },
    mounted() {
        this.getPartnerName();
    },
};
