//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page';
import COMMON_METHODS from '@mixins/common-methods.js';
import Add from './add.vue';
import uploadfp from './components/uploadfp.vue';
import uploadpz from './components/uploadpz.vue';
import billStatus from './data/billStatus';

export default {
    mixins: [LIST_MIXIN, COMMON_METHODS],
    props: [],
    components: { Add, uploadpz, uploadfp },
    data: function () {
        return {
            pgData: {
                billName: '',
                billStatus: '',
                channelId: '',
                productId: '',
            },
            tableData: [],

            billId: '',
            token: '',
            listStatus: billStatus,
            
            partnerNamelist: [],
        };
    },
    computed: {},

    methods: {
        doSearch: function () {
            this.$refs.page.queryData();
        },
        //清单详情
        listDetail: function (row) {
            var query = {
                    billId: row.billId,
                    token: this.getQuery('token'),
                },
                token = this.getQuery('token');

            if (token) {
                query.token = token;
            }

            this.goto({
                path: './detail',
                query,
            });
        },
        //导出清单
        exportList: function () {
            if (this.tableData.length != 0) {
                var search = toSearch({
                    ...this.pgData,
                    token:
                        this.getQuery('token') || this.getGetters('user').token,
                    isExport: true,
                });
                window.open(`${this.$store.getters.indexUrl}/settle/settBill/listPage${search}`);
            } else {
                showMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        //获取合作单位
        getPartnerName: function () {
            this.$get(
                `${this.getGetters('partnerUrl')}/channelInfo/listSelect`,
                {},
                (data) => {
                    this.partnerNamelist = data;
                }
            );
        },
    },
    mounted() {
        this.getPartnerName();
    },
};
